<template>
  <section>
    <h1 class="mb-3">
      Meus Dados
    </h1>
    <validation-observer ref="formItems">
      <b-form>
        <h3>Dados Pessoais</h3>
        <b-row>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >
            <validation-provider
              #default="{ errors }"
              name="Nome"
              rules="required"
            >
              <b-form-group
                label="Nome Completo *"
                label-for="nome"
              >
                <b-form-input
                  id="nome"
                  v-model="form.nome"
                  name="nome"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            md="6"
            xl="6"
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="E-mail *"
              label-for="email-input"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="email-input"
                  v-model="form.email"
                  autocomplete="off"
                  placeholder="Email"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            md="4"
            xl="4"
            sm="12"
            class="mb-1"
          >
            <validation-provider
              #default="{ errors }"
              name="CPF"
              rules="required|cpf"
            >
              <b-form-group
                label="CPF *"
                label-for="cpf-input"
              >
                <b-form-input
                  id="cpf"
                  v-model="form.cpf"
                  v-mask="'###.###.###-##'"
                  placeholder="000.000.000-00"
                  autocomplete="off"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            md="4"
            xl="4"
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Data de nascimento*"
              label-for="nascimento"
            >
              <validation-provider
                #default="{ errors }"
                name="Data de nascimento"
                rules="required|nascimento"
              >
                <cleave
                  id="nascimento"
                  v-model="form.nascimento"
                  class="form-control"
                  :raw="false"
                  :options="options.date"
                  autocomplete="off"
                  placeholder="00/00/0000"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            xl="4"
            class="mb-1"
          >
            <validation-provider
              #default="{ errors }"
              name="Sexo"
              rules="required"
            >
              <b-form-group
                label="Sexo *"
                label-for="sexo"
              >
                <v-select
                  id="sexo"
                  v-model="form.sexo"
                  :options="sex"
                  variant="custom"
                  item-text="name"
                  item-value="name"
                  placeholder="Selecione uma opção"
                  label="name"
                >
                  <template v-slot:no-options>
                    Nenhum registro encontrado.
                  </template>
                </v-select>
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  Este campo é de preenchimento obrigatório.
                  <br>
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-button
              type="button"
              class="mr-1"
              variant="custom-blue"
              @click="formSubmit()"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-50"
              />
              Salvar alterações
            </b-button>
            <b-button
              variant="outline-danger"
              @click="redirectToHome"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,

} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, between, min, length, email, cpf,
} from '@validations'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import { strClear } from '@core/utils/utils'
import { successMessage, warningInfoMessage, warningMessage } from '@/libs/sweetalerts'
import { getUserByMyAccount, updateUserByMyAccount } from '@/views/custom-pages/minha-conta/requests'

export default {
  title: "Minha conta",

  components: {
    BForm,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    Cleave,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      between,
      min,
      length,
      email,
      cpf,
      moment,
      saving: false,
      modal: {
        showModal: false,
        typeModal: 'danger',
      },
      modalSucesso: {
        showModal: false,
        typeModal: 'success',
        caseModal: 'edicao',
      },
      render: true,
      dadosUsuario: {},
      loadingShow: false,
      form: {
        nome: '',
        cpf: '',
        nascimento: '',
        email: '',
        telefone: '',
        sexo: null,
        perfil: null,
        departamento: null,
        unidades: null,
        empresas: [],
        nome_mae: null,
        id_nacionalidade: null,
        errorApi: {
          error: '',
          nome: '',
          email: '',
          cpf: '',
          nascimento: '',
          sexo: '',
          perfil: '',
          departamento: '',
          nome_mae: "",
          id_nacionalidade: "",
        },
        setado: false,
      },
      input: {
        nascimento: "",
      },
      cpfValido: true,
      cpfExiste: false,
      cpfError: false,
      options: {
        date: {
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        },
      },

      sex: [{ name: 'Masculino' }, { name: 'Feminino' }],
    }
  },

  mounted() {
    this.index()
  },

  methods: {
    async index() {
      this.setLoading(true)

      await getUserByMyAccount()
        .then(response => {
          if (response.status === 200) {
            const { data } = response

            this.$store.commit('sessions/SET_USER_NAME', data.nome)

            this.form.nome = data.nome
            this.form.cpf = data.cpf
            this.form.nascimento = moment(data.data_nascimento).format('DD/MM/YYYY')
            this.form.email = data.email
            this.form.nome_mae = data.nome_mae
            this.form.id_nacionalidade = data.id_nacionalidade
            this.form.sexo = data.genero ? { name: data.genero } : null
            this.form.perfil = data.perfil.map((p) => p.id_perfil)
          }
        })
        .catch(error => {
          this.handleError(error.response)
        })

      this.setLoading(false)
    },

    async formSubmit() {
      const resultFormItems = new Promise(resolve => {
        this.$refs.formItems.validate()
          .then(success => {
            if (success) {
              resolve(true)
            }
          })
      })

      if (await resultFormItems) {
        await this.handleUpdate()
      }
    },

    async handleUpdate() {
      this.setLoading(true)

      const formData = {
        nome: this.form.nome,
        cpf: strClear(this.form.cpf),
        dataNascimento: moment(this.form.nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        sexo: this.form.sexo.name,
        email: this.form.email,
        perfil: this.form.perfil,
        departamento: this.form.departamento,
        unidades: this.form.unidades != null && this.form.unidades.length > 0 ? getArrayAttr(this.form.unidades, 'id_unidade') : [],
        nomeMae: this.form.nome_mae,
        idNacionalidade: this.form.id_nacionalidade,
      }

      await updateUserByMyAccount(formData)
        .then(response => {
          if (response.status === 200) {
            this.form.nome = response.data.nome
            this.$store.commit(
              'sessions/SET_USER_NAME',
              this.form.nome,
            )

            successMessage('O registro foi alterado com sucesso!')
          }
        })
        .catch(error => {
          this.handleError(error.response)
        })

      this.setLoading(false)
    },

    handleError(response) {
      const errors = response.status === 400 || response.status === 404

      if (errors) {
        warningInfoMessage(
          response.data.errors,
          'Confira os dados e depois salve o usuário.',
          'Entendido',
        )

        return
      }

      warningMessage('Não foi possível realizar a sua solicitação.<br /> Entre em contato com o suporte.')
    },

    atualizarUsuarioEmpresa() {
      this.saving = true
      this.disabledAll = true

      const { id } = this.dadosUsuario

      const formData = {
        nome: this.form.nome,
        cpf: this.form.cpf,
        dataNascimento: moment(this.form.nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        sexo: this.form.sexo,
        email: this.form.email,
        empresasId: this.form.empresas,
        nomeMae: this.form.nome_mae,
        idNacionalidade: this.form.id_nacionalidade,
      }

      updateCompanyUser(id, formData).then(() => {
        this.saving = false
        this.$store.commit(
          'sessions/SET_USER_NAME',
          this.form.nome,
        )
        this.buscaDadosEditar()
        this.openModalSucesso()
      }).catch(error => {
        this.handleError(error.response)

        this.saving = false
        this.disabledAll = false
      })
    },

    reload() {
      this.render = false
      this.buscaDadosEditar()
      this.$nextTick(() => {
        this.render = true
      })
    },
    preencheFormulario(dados) {
      this.$store.commit("sessions/SET_USER_NAME", dados.nome);

      this.form.nome = dados.nome
      this.form.cpf = dados.cpf
      this.form.nascimento = moment(dados.data_nascimento).format('DD/MM/YYYY')
      this.form.email = dados.email
      this.form.sexo = dados.sexo
      this.form.nome_mae = dados.nome_mae;
      this.form.id_nacionalidade = dados.id_nacionalidade;

      if (this.perfilEmpresaUsuarioAutenticado) {
        this.form.empresas = dados.empresa.length > 0 ? getArrayAttr(dados.empresa, 'id_empresa') : []
      }

      if (!this.perfilEmpresaUsuarioAutenticado) {
        this.form.perfil = dados.perfil[0].id_perfil
        this.form.departamento = dados.id_departamento
        this.form.unidades = dados.unidade
      }

      this.setNascimentoPicker()
      this.loadingShow = false
    },

    limpaForm() {
      this.form.cpf = ''
      this.form.nome = ''
      this.form.cpf = ''
      this.form.email = ''
      this.form.nascimento = ''
      this.form.sexo = ''
      this.form.empresas = []

      this.form.errorApi.cpf = ''
      this.form.errorApi.nome = ''
      this.form.errorApi.email = ''
      this.form.errorApi.nascimento = ''
      this.form.errorApi.sexo = ''
    },
    async buscaDadosEditar() {
      this.loadingShow = true

      if (this.perfilEmpresaUsuarioAutenticado) {
        await getCompanyUserId(this.dadosUsuario.id)
          .then(data => {
            this.preencheFormulario(data.data)
          })
          .catch(error => {
            this.handleError(error.response)
          })
      } else {
        await getAdminSesiId(this.dadosUsuario.id)
          .then(data => {
            this.preencheFormulario(data.data)
          })
          .catch(error => {
            this.handleError(error.response)
          })
      }

      this.loadingShow = false
    },
    setNascimentoPicker() {
      this.form.errorApi.nascimento = ''
      this.input.nascimento = moment(this.form.nascimento, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    setNascimentoInput() {
      this.form.errorApi.nascimento = ''
      this.form.nascimento = moment(this.input.nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD')
    },
    openModalConfirm() {
      this.modal.showModal = true;
    },
    closeModalConfirm() {
      this.modal.showModal = false;
    },
    openModalSucesso() {
      this.modalSucesso.showModal = true;
    },
    closeModalSucesso() {
      this.modalSucesso.showModal = false;
    },
    redirectToHome() {
      this.$router.push({ name: "home" });
    },
    updateNacionalidade(id_nacionalidade) {
      this.form.id_nacionalidade = id_nacionalidade;
    },
    setLoading(loading) {
      this.$emit('setLoading', loading)
    },
  },
};
</script>

<style scoped>
.formulario {
  margin-top: 20px;
}
</style>
