var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('h1',{staticClass:"mb-3"},[_vm._v(" Meus Dados ")]),_c('validation-observer',{ref:"formItems"},[_c('b-form',[_c('h3',[_vm._v("Dados Pessoais")]),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome Completo *","label-for":"nome"}},[_c('b-form-input',{attrs:{"id":"nome","name":"nome"},model:{value:(_vm.form.nome),callback:function ($$v) {_vm.$set(_vm.form, "nome", $$v)},expression:"form.nome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"E-mail *","label-for":"email-input"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email-input","autocomplete":"off","placeholder":"Email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"4","xl":"4","sm":"12"}},[_c('validation-provider',{attrs:{"name":"CPF","rules":"required|cpf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CPF *","label-for":"cpf-input"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"id":"cpf","placeholder":"000.000.000-00","autocomplete":"off"},model:{value:(_vm.form.cpf),callback:function ($$v) {_vm.$set(_vm.form, "cpf", $$v)},expression:"form.cpf"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"4","xl":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Data de nascimento*","label-for":"nascimento"}},[_c('validation-provider',{attrs:{"name":"Data de nascimento","rules":"required|nascimento"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"nascimento","raw":false,"options":_vm.options.date,"autocomplete":"off","placeholder":"00/00/0000"},model:{value:(_vm.form.nascimento),callback:function ($$v) {_vm.$set(_vm.form, "nascimento", $$v)},expression:"form.nascimento"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"4","xl":"4"}},[_c('validation-provider',{attrs:{"name":"Sexo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Sexo *","label-for":"sexo"}},[_c('v-select',{attrs:{"id":"sexo","options":_vm.sex,"variant":"custom","item-text":"name","item-value":"name","placeholder":"Selecione uma opção","label":"name"},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Nenhum registro encontrado. ")]},proxy:true}],null,true),model:{value:(_vm.form.sexo),callback:function ($$v) {_vm.$set(_vm.form, "sexo", $$v)},expression:"form.sexo"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. "),_c('br')]):_vm._e()],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"button","variant":"custom-blue"},on:{"click":function($event){return _vm.formSubmit()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_vm._v(" Salvar alterações ")],1),_c('b-button',{attrs:{"variant":"outline-danger"},on:{"click":_vm.redirectToHome}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }