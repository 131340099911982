export default {
    handleErrosMinhaConta(errorData, errorForm) {
        let error = errorData.errors;

        errorForm.nome = error['pessoa.nome'] ? error['pessoa.nome'][0] : '';
        errorForm.email = error.email ? error.email[0] : '';
        errorForm.cpf = error['pessoa.cpf'] ? error['pessoa.cpf'][0] : '';
        errorForm.nascimento = error['pessoa.data_nascimento'] ? error['pessoa.data_nascimento'][0] : '';
        errorForm.genero = error['pessoa.genero'] ? error['pessoa.genero'][0] : '';
        errorForm.nome_mae = error['pessoa.nome_mae'] ? error['pessoa.nome_mae'][0] : '';
        errorForm.id_nacionalidade = error['pessoa.id_nacionalidade'] ? error['pessoa.id_nacionalidade'][0] : '';
        errorForm.error = '';

        if(typeof error == 'string') {
            errorForm.error = error;
        }
    },
    handleErrosSenha(errorData, errorForm) {
        let error = errorData.errors;

        errorForm.password = error.password ? error.password[0] : '';
        errorForm.newPassword = error.newPassword ? error.new_password[0] : '';
        errorForm.passwordConfirmation = error.passwordConfirmation ? error.password_confirmation[0] : '';
        errorForm.error = '';

        if(typeof error == 'string') {
            errorForm.error = error;
        }
    }
}