<template>
  <b-overlay variant="white" :show="loadingShow" opacity="1">
    <ConfirmModal
      itemIdentifier="usuário"
      :modalShow="modal.showModal"
      :typeModal="modal.typeModal"
      @confirmed="limparCampos"
      @canceled="closeModalConfirm"
    />
    
    <SucessoModal
        :modalShow="modalSucesso.showModal"
        :typeModal="modalSucesso.typeModal"
        :caseModal="modalSucesso.caseModal"
        @confirmed="closeModalSucesso"
    />

    
    <h1 class="mb-3">Segurança</h1>
    <div class="mb-1">
      <validation-observer ref="senhaRules">
        <b-form>
          <div class="formulario" id="senhas">
            <h3>Redefina sua senha</h3>
              
            <b-row>
              <b-col md="6" xl="6" class="mb-1">
                <validation-provider
                  name="Senha Atual"
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group 
                  :state="errors.length > 0 || senha.errorApi.password ? false:null"
                  label="Senha Atual" 
                  label-for="senha-atual">
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        :type="tipo.atual"
                        v-model="senha.atual"
                        id="atual"
                        name="senha-atual"
                        :disabled="formDisabled.atual"
                        placeholder="******"
                        autocomplete="off"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="icone.atual"
                          @click="mostraSenha('atual')"
                        />
                      </b-input-group-append>
                    </b-input-group>

                    <small v-if="errors[0]" class="text-danger">
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small v-if="senha.errorApi.password" class="text-danger">
                      {{ senha.errorApi.password }}
                    </small>
                    <small v-if="senha.errorApi.error" class="text-danger">
                      {{ senha.errorApi.error }}
                    </small>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  name="Nova Senha"
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group 
                  :state="errors.length > 0 || senha.errorApi.new_password ? false:null"
                  label="Nova Senha" 
                  label-for="senha-nova">
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        :type="tipo.nova"
                        v-model="senha.nova"
                        id="nova"
                        name="senha-nova"
                        @input="verificaSenha"
                        :disabled="formDisabled.nova"
                        placeholder="******"
                        autocomplete="off"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="icone.nova"
                          @click="mostraSenha('nova')"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small v-if="!validacao.regras && senha.nova && !senha.errorApi.new_password" class="text-danger"
                      >
                        A senha digitada não está de acordo com as regras.
                      <br
                    /></small>
                    <small v-if="errors[0]" class="text-danger">
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small v-if="senha.errorApi.new_password" class="text-danger">
                      {{ senha.errorApi.new_password }}
                    </small>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  name="Confirme nova Senha"
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label="Confirme a nova Senha"
                    label-for="senha-confirma"
                  >
                    <b-input-group
                      :state="errors.length > 0 || senha.errorApi.password_confirmation ? false:null"
                      class="input-group-merge"
                    >
                      <b-form-input
                        :type="tipo.confirma"
                        v-model="senha.confirma"
                        id="confirma"
                        @input="verificaSenha"
                        name="senha-confirma"
                        :disabled="formDisabled.confirma"
                        placeholder="******"
                        autocomplete="off"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="icone.confirma"
                          @click="mostraSenha('confirma')"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small
                      v-if="validacao.confirmaNaoConfere && !senha.errorApi.password_confirmation"
                      class="text-danger"
                      >{{
                        "O campo confirmação de senha deve ser igual ao campo nova senha."
                      }}<br
                    /></small>
                    <small v-if="errors[0]" class="text-danger">
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small v-if="senha.errorApi.password_confirmation" class="text-danger">
                      {{ senha.errorApi.password_confirmation }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" xl="6" class="mb-sm-1">
                <ul class="regras">
                  <b-row class="itens"
                    ><feather-icon
                      icon="CheckCircleIcon"
                      size="16"
                      class="mr-75 icone"
                    />Sua senha deve conter entre 8 e 16 caracteres</b-row
                  >

                  <b-row>
                    <feather-icon
                      icon="CheckCircleIcon"
                      size="16"
                      class="mr-75 icone"
                    />
                    Utilize ao menos duas das seguintes opções:
                  </b-row>
                  <b-row class="itens">
                    <ul>
                      <li>Letra Maiúscula</li>
                      <li>Letra Minúscula</li>
                      <li>Número</li>
                      <li>Caractere Especial</li>
                    </ul>
                  </b-row>
                  <b-row class="itens">
                    <feather-icon
                      icon="SlashIcon"
                      size="16"
                      class="mr-75 text-danger"
                    />Não utilize caracteres com acento</b-row
                  >
                </ul>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-button
                  type="submit"
                  class="mr-1"
                  variant="custom-blue"
                  @click.prevent="validationForm(true)"
                >
                <feather-icon icon="CheckIcon" class="mr-50"/>
                <span class="align-middle">Salvar alterações</span>
                <span v-if="saving" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                </b-button>
                <b-button
                  variant="outline-danger"
                  @click="redirectToHome"
                >
                  Cancelar
                </b-button>

              </b-col>
            </b-row>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </b-overlay>
</template>

<script>
import {
  BSpinner,
  BOverlay,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BForm,
  BAlert,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver} from "vee-validate";
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue'
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue'
import { required } from "@validations";
import handleErrorsService from './Services/HandleErrors';
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { validation } from "@core/mixins/validation/validation";
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BSpinner,
    BOverlay,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BForm,
    BAlert,
    ValidationProvider,
    ValidationObserver,
    ConfirmModal, SucessoModal
  },

  mixins: [togglePasswordVisibility, validation],

  data() {
    return {
      required,
      erros: null,
      loadingShow: false,
      saving: false,
      dadosUsuario: {},
      validacao: {
        confirmaNaoConfere: false,
        regras: false,
      },
      modal: {
        showModal: false,
        typeModal: 'danger'
      },
      modalSucesso: {
          showModal: false,
          typeModal: 'success',
          caseModal: 'edicao'
      },
      formDisabled: {
        atual: false,
        nova: false,
        confirma: false,
      },
      tipo: {
        atual: "password",
        nova: "password",
        confirma: "password",
      },
      senha: {
        atual: "",
        nova: "",
        confirma: "",
        errorApi: {
          password: "",
          new_password: "",
          password_confirmation: "",
          error: "",
        }
      },
      icone: {
        atual: "EyeOffIcon",
        nova: "EyeOffIcon",
        confirma: "EyeOffIcon",
      },
    }
  },
  created() {
    this.dadosUsuario = getUserData();
  },

  methods: {
    mostraSenha(campo) {
      if (campo == "atual") {
        this.tipo.atual = this.tipo.atual === "password" ? "text" : "password";
        this.icone.atual =
          this.tipo.atual === "password" ? "EyeOffIcon" : "EyeIcon";
      }
      if (campo == "nova") {
        this.tipo.nova = this.tipo.nova === "password" ? "text" : "password";
        this.icone.nova =
          this.tipo.nova === "password" ? "EyeOffIcon" : "EyeIcon";
      }
      if (campo == "confirma") {
        this.tipo.confirma =
          this.tipo.confirma === "password" ? "text" : "password";
        this.icone.confirma =
          this.tipo.confirma === "password" ? "EyeOffIcon" : "EyeIcon";
      }
    },
    limparCampos() {
      this.senha.atual = "";
      this.senha.nova = "";
      this.senha.confirma = "";
      this.validacao.confirmaNaoConfere = false;
      this.validacao.regras = false;
      this.$refs.senhaRules.reset();

      this.senha.errorApi.password = '';
      this.senha.errorApi.new_password = '';
      this.senha.errorApi.password_confirmation = '';
      this.senha.errorApi.error = '';

      this.errorShow = false;
      this.closeModalConfirm();
    },
    verificaSenha() {
      this.validacao.confirmaNaoConfere =
        this.senha.confirma != this.senha.nova;

      this.validacao.regras = this.passwordValidate(this.senha.nova);
      if(typeof this.validacao.regras == typeof undefined){
        this.validacao.regras = true;
      }
      return this.validacao.confirmaNaoConfere || this.validacao.regra;
    },
    async updateSenha() {
      const parameters = {
        password: this.senha.atual,
        new_password: this.senha.nova,
        password_confirmation: this.senha.confirma,
      };

      this.loadingShow = true;
      this.$http.put(this.$api.redefinirSenha(), parameters)
      .then(() => {
            this.saving = false;
            this.limparCampos();
            this.openModalSucesso();
            this.loadingShow = false;

        }).catch(({response: {data}}) => {
            this.loadingShow = false;
            handleErrorsService.handleErrosSenha(data, this.senha.errorApi);
            this.saving = false;
        })
    },
    validationForm(redirectToList) {
        this.$refs.senhaRules.validate().then(success => {
            if (success) {
                redirectToList ? this.saving = true : this.savingAndNew = true;
                this.updateSenha();
            }
        })
    },
    openModalConfirm() {
        this.modal.showModal = true;
    },
    closeModalConfirm() {
        this.modal.showModal = false;
    },
    openModalSucesso() {
        this.modalSucesso.showModal = true;
    },
    closeModalSucesso() {
        this.modalSucesso.showModal = false;
    },

    redirectToHome() {
      this.$router.replace({ name: 'home' })
    },
  },
};
</script>

<style lang="scss" scoped>
.icone {
  color: #2772c0;
}
.regras {
  /* Body / Paragraph */
  margin-top: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding-left: 20px;

  /* or 150% */

  /* 1) Theme Color / Body - Text */

  color: #6e6b7b;

  li {
    line-height: 25px;
  }
  .itens {
    margin-bottom: 10px;
  }
}
</style>
