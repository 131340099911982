import axiosIns from '@/libs/axios'
import rotasAPI from '@/router/rotasAPI'

export const getUserByMyAccount = params => new Promise((resolve, reject) => {
  axiosIns
    .get(rotasAPI.minhaConta(), { params })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const updateUserByMyAccount = form => new Promise((resolve, reject) => {
  axiosIns
    .put(rotasAPI.minhaConta(), form)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})
