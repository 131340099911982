<template>
  <div class="content-wrapper">
    <PageHeader
      screen-name="Meus Dados"
      :link-items="linkItems"
    />
    <overlay
      :show="loading"
    >
      <b-card
        class="p-0"
        no-body
      >
        <b-row class="position-absolute mt-5 ml-2">
          <b-card
            class="card rounded-2 mx-md-2 mt-1 mr-1 logo-empresa d-flex align-items-center py-2"
            no-body
          >
            <h1>{{ dadosUsuario.fullName[0] }}</h1>
          </b-card>
          <h1
            class="text-white mt-sm-4 mt-2"
            style="max-width: 60vw;"
          >
            {{ dadosUsuario.fullName }}
          </h1>
        </b-row>

        <div class="box-img-bg">
          <img
            alt="bg"
            :src="imgBg"
          >
        </div>
        <div class="m-2 mt-3 mt-sm-2">
          <b-tabs
            pills
            align="center"
          >
            <b-tab
              title="Meus Dados"
              active
            >
              <MeusDados
                @setLoading="setLoading"
              />
            </b-tab>
            <b-tab
              title="Segurança"
              lazy
            >
              <Seguranca />
            </b-tab>
          </b-tabs>
        </div>
      </b-card>
    </overlay>
  </div>
</template>

<script>
import {
  BRow, BCard, BTabs, BTab,
} from 'bootstrap-vue'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import MeusDados from '@/views/custom-pages/minha-conta/Meus-dados.vue'
import Seguranca from '@/views/custom-pages/minha-conta/Seguranca.vue'
import { getUserData } from '@/auth/utils'
import Overlay from '@/views/components/custom/overlay/Overlay.vue'

export default {
  components: {
    Overlay,
    BRow,
    BCard,
    BTabs,
    BTab,
    PageHeader,
    MeusDados,
    Seguranca,
  },
  data() {
    return {
      linkItems: [
        {
          name: 'Minha conta',
          active: true,
        },
        {
          name: 'Meus dados',
          active: true,
        },
      ],

      loading: true,

      imgBg: require('@/assets/custom-images/bg/bg-minha-conta.png'),
      dadosUsuario: getUserData(),
    }
  },

  methods: {
    setLoading(loading) {
      this.loading = loading
    },
  },
}
</script>

<style>
.box-img-bg img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    object-position: 0% 100%;
    border-radius: 0.3rem 0.3rem 0 0;
    overflow: hidden;
}
.logo-empresa{
    height: 100px;
    width: 100px;
    background-color:#002060;
    border: 5px solid #fff;
}
.logo-empresa h1{
    font-size: 5rem;
    line-height: 4rem;
    font-style: italic;
    color: white;
    padding-right: 0.5rem;
}
.nav-pills .nav-link.active {
    border-color: #2772c0;
    background-color: #2772c0;
    box-shadow: 0 4px 18px -4px #b9b9c3;
}
</style>
